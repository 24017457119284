
.img-comp {
  width: 100%;
  display: flex;
  justify-content: center;
  background: repeating-conic-gradient(rgb(246, 246, 249) 0%, rgb(246, 246, 249) 25%, transparent 0%, transparent 50%) 50% center / 20px 20px;
}

.base-comp {
  width: 100%;
  display: flex;
  justify-content: center;
}

.audio-comp {
  padding: 20px;
  align-items: center;
}

.sortable-checked {
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
}