@import "src/index";


.folder-icon {
  width: 80px !important;
  height: 80px !important;
  @include media("max", "sm") {
    width: 60px !important;
    height: 60px !important;
  }
}

.folder-text {
  width: 100px !important;
  text-align: center;
  word-break: break-all;
  @include media("max", "sm") {
    width: 60px !important;
  }
}

.folder-add {
  width: 102px;
  height: 102px;
  text-align: center;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: #1677ff;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;

    button {
      border: 0;
      background: unset;
      cursor: pointer;
    }
  }
}

.asset-lib {
  .ant-upload-drag {
    background: unset;
    cursor: unset;
  }
}