.icon-tag {
  font-size: 20px;
  padding: 10px;
  width: 40px;
}

.action-card {
  cursor: pointer;

  .action-title {
    text-align: center;
  }
}

.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.is-blocked {
  cursor: not-allowed;
}