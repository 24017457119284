.logo {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.sidebar-s {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between
  }
}

.ant-layout-sider-collapsed.ant-layout-sider li svg {
    transform: scale(1.3);
}