@import "src/index";

.c-builder-menu {
  height: 100vh;
  background: white;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: auto !important;
  position: fixed !important;
  left: 60px !important;
  top: 0 !important;
  bottom: 0 !important;
  @include media("max", "lg") {
    position: absolute !important;
    z-index: 1000 !important;
    max-width: 100vw !important;
    transition: all 0.3s !important;
  }
}


.c-builder-close {
  cursor: pointer;
  @include media("min", "lg") {
    display: none;
  }
}

.c-builder-c {
  font-size: 14px !important;
  padding-bottom: 1.5rem !important;

  .ant-collapse-header {
    padding: 0 !important;
    font-weight: 600 !important;
    color: rgb(0 21 42) !important;
    padding-top: 10px !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.cc-ul {
  list-style-type: none;
  padding-left: 15px;
}

.li-row {
  padding: 8px;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: black;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.cc-active {
  color: #1677ff;

  &:before {
    background: #1677ff;
  }
}

