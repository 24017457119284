$sizes: ("xs":320px, "sm":576px, "md":768px, "lg":992px, "xl":1200px);

@mixin media($minmax, $media) {
  @each $size, $resolution in $sizes {
    @if $media == $size {
      @media only screen and (#{$minmax}-width: $resolution) {
        @content;
      }
    }
  }
}

body {
  background: #f6f6f9;
  margin: 0;
}

.ant-card {
  color: rgb(50, 50, 77);
}

.demo-logo {
  width: 120px;
  min-width: 120px;
  height: 32px;
  /*background: rgba(255, 255, 255, .2);*/
  border-radius: 6px;
  margin-inline-end: 24px;
}

.ant-app {
  color: #212529;
}

.fw-normal {
  font-weight: normal;
}

.p-3 {
  padding: 1.5rem;
    @include media("max", "md") {
    padding: 0.5rem;
  }
}

.pt-3 {
  padding-top: 1.5rem;
}

.pl-3 {
  padding-left: 1.5rem;
}

.pr-3 {
  padding-right: 1.5rem;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.fw-500 {
  font-weight: 500;
}
.ant-form-item-label {
  label {
    font-weight: 500;
  }
}