.c-field-item {
  margin: 0!important;
  background: unset!important;
  padding: 0!important;

  .ant-form-item-label {
    padding: 0!important;
  }

  .ant-form-item-control {
    display: none;
  }

}

.c-collapse {
  margin-bottom: 20px;
}