@import "src/index";

.attr-kind-icon {
  font-size: 20px;
  padding: 10px;
}

.attr-kind-grid {
  width: 50% !important;
  cursor: pointer;
  padding: 18px !important;
  @include media("max", "md") {
    width: 100% !important;
  }

  .description {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.attr-create-body {
  padding-left: 10px;
  padding-right: 10px;
  @include media("max", "md") {
    padding-left: 0;
    padding-right: 0;
  }
}