.login-card {
  margin-bottom: 30px;
  width: 100%;
  @media screen and (orientation: landscape) {
    width: 400px;
    margin-top: 100px;
  }
  padding-bottom: 15px;
}

.login-btn {
  width: 100%;
}